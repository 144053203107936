import React, { useEffect, useState } from 'react';
import { Select, SelectItem } from 'carbon-components-react';

interface DemoLanguageSelectionProps {
  selectedDemoConfig: string;
  langMatrix: string;
  localhostAddr: string;
  langLabel: string;
  func: (event: React.ChangeEvent<HTMLSelectElement>) => void;
}

interface DemoConfig {
  config: string;
  label: string;
}

interface LanguageMatrix {
  demos: Record<string, DemoConfig>;
}

const fetchLanguageMatrix = async (
  matrixFileName: string,
  localhostAddr: string,
): Promise<LanguageMatrix> => {
  const isLocalhost = window.location.hostname === 'localhost';
  const baseUrl =
    isLocalhost && localhostAddr && localhostAddr.trim() !== ''
      ? `http://localhost:${localhostAddr
          ?.trim()
          .replaceAll('-', '/')
          .replace(/\/$/, '')}`
      : 'https://ace-project-bucket.s3.us-east.cloud-object-storage.appdomain.cloud/aem-demo-configs';

  const url = `${baseUrl}/${matrixFileName}.json`;

  try {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error(
        `Config file could not be loaded. Please ensure ${matrixFileName} exists.`,
      );
    }
    return await response.json();
  } catch (error) {
    const errorMessage =
      error instanceof Error ? error.message : 'An unknown error occurred';
    console.error('Error fetching language matrix:', errorMessage);
    throw error;
  }
};

const DemoLanguageSelection: React.FC<DemoLanguageSelectionProps> = ({
  selectedDemoConfig,
  langMatrix,
  localhostAddr,
  langLabel,
  func,
}) => {
  const [languageMatrix, setLanguageMatrix] = useState<LanguageMatrix | null>(
    null,
  );

  useEffect(() => {
    const loadLanguageMatrix = async () => {
      try {
        const matrix = await fetchLanguageMatrix(langMatrix, localhostAddr);
        setLanguageMatrix(matrix);
      } catch (error) {
        console.error('Failed to load language matrix:', error);
      }
    };
    loadLanguageMatrix();
  }, [langMatrix, localhostAddr]);

  if (!languageMatrix) {
    return <p>...</p>;
  }

  return (
    <div className="languageSelection">
      <Select
        id="languageSelection"
        defaultValue={selectedDemoConfig}
        inline
        labelText={langLabel}
        onChange={(event) => func(event)}
      >
        {Object.entries(languageMatrix.demos).map(
          ([key, { config, label }]) => (
            <SelectItem
              id={key}
              value={config}
              data-config={config}
              text={label}
            />
          ),
        )}
      </Select>
    </div>
  );
};

export default DemoLanguageSelection;

import React, { useRef } from 'react';
import { FigmaConfig } from '../types/types';
import DemoCtas from './DemoCtas';
import VideoComp from './VideoComp';
import { Link } from 'carbon-components-react';
import { ArrowRight16 } from '@carbon/icons-react';

interface EducationVideoProps {
  configObj: FigmaConfig; // Video identifier (could be a YouTube, Vimeo ID, etc.)
  styleOverride?: object; // Optional description of the video
  userLocale: string;
  environment: string;
}

const EducationVideo: React.FC<EducationVideoProps> = ({
  configObj,
  styleOverride,
  userLocale,
  environment,
}) => {
  // Construct video source URL (YouTube example)
  const videoWrapRef = useRef<HTMLDivElement | null>(null);

  const handleClick = (start: number) => {
    const videoPlayer = document.querySelector(`dds-video-player`);
    if (videoPlayer) {
      const vidContainer = videoPlayer.querySelector(
        '.bx--video-player__video',
      ) as unknown as {
        sendNotification: (command: string, value: unknown) => void;
      };
      if (vidContainer) {
        vidContainer.sendNotification('doSeek', start);
      }
    }
  };

  return (
    <div
      className="widget-styles figma_demo_widget_wrap2"
      style={styleOverride}
    >
      <div className="figma_demo_header">
        {configObj?.ctas && (
          <DemoCtas
            urxlang={userLocale}
            ctas={configObj.ctas}
            env={environment}
            demotitle={configObj.demo_title}
            producttitle={configObj.product_title}
            toggleButtons={undefined}
            smallScreenMessage={''}
          />
        )}
      </div>
      <div className="figma_demo_body">
        <div
          className="bx--grid media_wrap"
          ref={videoWrapRef}
          style={{ paddingLeft: 0 }}
        >
          <div className="bx--row">
            <div className="bx--col-lg-12 bx--col-md-5 bx--col-sm-12">
              <VideoComp mediaId={configObj.education_video.video_id} />
            </div>
            <div className="bx--col-lg-3 bx--col-md-3 bx--col-sm-12">
              {configObj?.education_video && (
                <>
                  <h4>{configObj.education_video.menu.title}</h4>
                  <ul>
                    {configObj.education_video.menu.list.map(
                      (
                        item: { Chapter: string; starts: number },
                        index: number,
                      ) => (
                        <li key={index}>
                          <Link
                            href="#"
                            renderIcon={ArrowRight16}
                            onClick={(
                              e: React.MouseEvent<HTMLAnchorElement>,
                            ) => {
                              e.preventDefault();
                              handleClick(item.starts);
                            }}
                          >
                            {item.Chapter}
                          </Link>
                        </li>
                      ),
                    )}
                  </ul>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EducationVideo;
